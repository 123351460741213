import styled from 'styled-components';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { commonActions } from '@aclito/shared/redux/slices';
import { useEffect } from 'react';
import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { useMediaQuery } from '@mantine/hooks';
import ThemeProvider from '@aclito/ui-web/providers/ThemeProvider';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';
import { Calendar as BigCalendar } from 'react-big-calendar';
import { EventWithDateObject } from '@aclito/shared/types';
import { Center, Loader } from '@mantine/core';

import withRouter from '../../navigation/withRouter';
import MobileCalendar from '../MobileCalendar/MobileCalendar';
import { CalendarStyleWrapper } from '../Calendar/CalendarStyleWrapper';

import { HStack } from '@/components';
import { TypedLocation } from '@/hooks/useLocation';
import { QueryEmbeddedCalendar } from '@/util/types';
import { components } from '@/common/Calendar';
import Language from '@/components/Language';
import { useModal } from '@/hooks/useModal';
import { formatEventWithDate } from '@/util/formatEventWithDateObject';
import { useEventInfoHandlers } from '@/features/event/screens/EventInfo/hooks/useEventInfoHandlers';
import { Storage } from '@/util/storage';
import { useCalendar } from '@/components/Calendar/hooks/useCalendar';
import { useLocalizer } from '@/components/Calendar/hooks/useLocalizer';
type EmbeddedCalendarProps = TypedLocation<void, QueryEmbeddedCalendar>;

const EmbeddedCalendar = ({ query }: EmbeddedCalendarProps) => {
  const orgId = query?.orgId;
  const theme = query?.theme ?? 'light';
  const dispatch = useAppDispatch();
  const locale = useAppSelector(commonSelectors.locale);
  const t = useTranslate();
  const { openModal } = useModal();
  const { handleRedirectToEventInfo } = useEventInfoHandlers();

  const mqTabletS = useMediaQuery(deviceMax.tabletS);

  document.body.style.height = '0';

  useEffect(() => {
    dispatch(commonActions.updateLocale({ locale }));
  }, [dispatch, locale]);

  const { mappedEvents, debouncedNavigate, debouncedRangeChange, isFetching } =
    useCalendar(orgId ?? '', true);
  const { formats, localizer } = useLocalizer();

  if (!orgId) {
    return <div>{t('validation.url')}</div>;
  }

  if (isFetching) {
    return (
      <LoaderContainer>
        <Center>
          <Loader />
        </Center>
      </LoaderContainer>
    );
  }

  return (
    <ThemeProvider Storage={Storage} defaultTheme={theme}>
      <ScreenWrapper>
        <TopContainer>
          <LanguageContainer>
            <Language />
          </LanguageContainer>
          <CalendarStyleWrapper>
            {mqTabletS ? (
              <MobileCalendar
                events={mappedEvents}
                onClick={(event) => {
                  openModal({
                    withCloseButton: false,
                    centered: true,
                    size: 'auto',
                    padding: 0,
                    type: 'embedCalModal',
                    styles: {
                      content: {
                        width: '30rem',
                      },
                    },
                    values: {
                      event: {
                        ...event,
                        date: new Date(event.date),
                        endDate: new Date(event.endDate),
                      },
                      navigation: handleRedirectToEventInfo,
                    },
                  });
                }}
              />
            ) : (
              <Container>
                <BigCalendar
                  localizer={localizer}
                  formats={formats}
                  {...{
                    components,
                    events: mappedEvents,
                    startAccessor: 'date',
                    endAccessor: 'endDate',
                    onNavigate: debouncedNavigate,
                    onRangeChange: debouncedRangeChange,
                    onSelectEvent: (event: EventWithDateObject) => {
                      openModal({
                        withCloseButton: false,
                        centered: true,
                        size: 'auto',
                        padding: 0,
                        type: 'embedCalModal',
                        styles: {
                          content: {
                            width: '30rem',
                          },
                        },
                        values: {
                          event: {
                            ...formatEventWithDate(event, t),
                            ...event,
                          },
                          navigation: handleRedirectToEventInfo,
                        },
                      });
                    },
                  }}
                />
              </Container>
            )}
          </CalendarStyleWrapper>
        </TopContainer>
      </ScreenWrapper>
    </ThemeProvider>
  );
};

const ScreenWrapper = styled.div`
  background: ${({ theme }) => theme.background};
  width: 100dvw;
  height: 100dvh;
  margin-top: -2rem;
`;

const TopContainer = styled.div`
  margin-top: 32px;
  height: 100vh;
  padding: 16px;
`;

const Container = styled.div`
  height: 800px;
  padding: 16px;
`;

const LanguageContainer = styled(HStack)`
  justify-content: flex-end;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export default withRouter(EmbeddedCalendar);
