import { Calendar, CalendarProps } from '@mantine/dates';
import React, { useEffect, useState } from 'react';
import { EventWithDateObject, FormattedEvent } from '@aclito/shared/types';
import { useTranslate } from '@aclito/shared/hooks';
import styled, { useTheme } from 'styled-components';
import {
  compareByMonthAndDayAndYear,
  compareByMonthAndYear,
} from '@aclito/shared/util/compareDates';
import moment from 'moment';

import EventsCards from '../Cards/EventsCards';

import CalendarDay from './components/CalendarDay';

import { Text, Icon, HStack, VStack } from '@/components';
import { formatEventWithDate } from '@/util/formatEventWithDateObject';
import { Figure1 } from '@/images';
interface MobileCalendarProps extends Omit<CalendarProps, 'onClick'> {
  events: EventWithDateObject[];
  onClick?: (event: FormattedEvent) => void;
  topBarElement?: React.ReactNode;
}

const MobileCalendar: React.FC<MobileCalendarProps> = ({
  events,
  onClick,
  topBarElement,
  ...props
}) => {
  const t = useTranslate();

  const [showCal, setShowCal] = useState(true);
  const [filteredEvents, setFilteredEvents] =
    useState<EventWithDateObject[]>(events);
  const [selectedDay, setSelectedDay] = useState<Date>();

  const theme = useTheme();

  const handleShowCal = () => {
    setShowCal(!showCal);
  };

  const onDateChange = (date: Date) => {
    const filtered = events.filter((event) =>
      compareByMonthAndYear(event.date, date),
    );

    setFilteredEvents(filtered);
  };

  const filterEventsByDay = (date: Date) =>
    events.filter((event) => compareByMonthAndDayAndYear(event.date, date));

  useEffect(() => {
    onDayClick(moment().startOf('D').toDate());
  }, [events]);

  const onDayClick = (date: Date) => {
    setFilteredEvents(filterEventsByDay(date));
    setSelectedDay(date);
  };

  return (
    events && (
      <VStack bg={theme.background} p={3}>
        <TopBar repeat={topBarElement ? 3 : 2} onClick={handleShowCal}>
          <Text
            style={{
              justifySelf: 'start',
            }}
            color={theme.text}
            text={'Calendar'}
            weight={500}
          />
          {topBarElement}
          <Icon
            style={{
              justifySelf: 'end',
            }}
            size={14}
            icon={showCal ? 'caretUp' : 'caretDown'}
          />
        </TopBar>
        {showCal && (
          <Calendar
            onPointerEnterCapture={() => {
              return;
            }}
            onPointerLeaveCapture={() => {
              return;
            }}
            placeholder={''}
            onDateChange={onDateChange}
            styles={{
              month: {
                width: '100%',
                height: '30vh',
              },
              monthCell: {
                textAlign: 'center',
                verticalAlign: 'middle',
              },
              calendarHeader: {
                margin: 'auto',
              },
              calendarHeaderLevel: {
                color: theme.text,
                ':hover': {
                  background: theme.grey10,
                },
              },
              calendarHeaderControl: {
                color: theme.text,
                ':hover': {
                  background: theme.grey10,
                },
              },
              day: {
                color: theme.black,
                ':hover': {
                  background: theme.grey10,
                },
              },
            }}
            {...props}
            renderDay={(date) => (
              <CalendarDay
                {...{
                  date,
                  eventsByDay: filterEventsByDay(date),
                  selectedDay,
                  onDayClick,
                }}
              />
            )}
          />
        )}
        {filteredEvents.length ? (
          <EventsCards
            showAds
            data={filteredEvents.map((e) => formatEventWithDate(e, t))}
            showDateType="day"
            onClick={onClick}
          />
        ) : (
          <VStack justify="center" align="center" spacing={40}>
            <Text tx="map.noevents" />
            <Figure1 />
          </VStack>
        )}
      </VStack>
    )
  );
};

const TopBar = styled(HStack)<{ repeat: number }>`
  display: inline-grid;
  grid-template-columns: ${({ repeat }) => `repeat(${repeat}, 1fr)`};
  width: 100%;
  height: 4.5vh;
  padding: 0 7%;
  background-color: ${({ theme }) => theme.grey10};
`;

export default MobileCalendar;
